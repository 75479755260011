import styled from 'styled-components'
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import aboutImgJpg from 'assets/images/about.jpg'
import aboutImgWebp from 'assets/images/about.webp'
import aboutMobileImgJpg from 'assets/images/about-mobile.jpg'
import aboutMobileImgWebp from 'assets/images/about-mobile.webp'

const aboutImg = isWebpSupported()
  ? aboutImgWebp
  : aboutImgJpg

const aboutMobileImg = isWebpSupported()
  ? aboutMobileImgWebp
  : aboutMobileImgJpg

const About = () => (
  <>
    <Comment>
      <p>
        Изысканные торты для особенных моментов в жизни: от пышной свадьбы, юбилея или светского праздника до уютного семейного торжества.
      </p>
    </Comment>
    <Section id='about'>
      <div>
        <Title>Философия Cake Buro</Title>
        <Description>
          Cake Buro — ателье авторских тортов от команды «Кофемании». Наши кондитеры — настоящие мастера своего дела, которые могут воплотить в жизнь даже самый невероятный замысел.
          <br />
          <br />
          В Cake Buro вы можете заказать торт для любого случая: будь то камерный день рождения или пышная свадьба с сотней гостей. От классических интерпретаций до настоящих арт-объектов — мы подберём что-то особенное для каждого праздника.
        </Description>
      </div>
      <BackgroundImage />
    </Section>
  </>
)

export default About

const Section = styled.section`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(0deg, #8EA1B1, #8EA1B1), #D1D8D8;
  padding: ${horizontalScale(160)} ${horizontalScale(221)} ${horizontalScale(160)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    flex-direction: column;
    position: relative;
    padding:
      ${mobileScale(64)}
      ${mobileScale(16)};
    margin-bottom: ${mobileScale(320)};

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-image: url(${aboutMobileImg});
      background-repeat: no-repeat;
      background-size: cover;
      /* background-position-y: ${mobileScale(-65)}; */
      width: ${mobileScale(320)};
      height: ${mobileScale(320)};
      left: 0;
      right: 0;
      top: 100%;
    }
  `)}
`

const BackgroundImage = styled.div`
  width: ${horizontalScale(573)};
  height: ${horizontalScale(710)};
  background-image: url(${aboutImg});
  background-repeat: no-repeat;
  background-size: ${horizontalScale(1141)} ${horizontalScale(1413)};
  background-position-y: ${horizontalScale(-34)};
  background-position-x: ${horizontalScale(-255)};

  ${mobileMediaQuery((css) => css`
    display: none;
  `)}
`

const Title = styled.h2`
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-align: left;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-top: ${horizontalScale(84)};
  margin-bottom: ${horizontalScale(55)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(32)};
    font-weight: normal;
    text-align: left;
  `)}
`

const Description = styled.p`
  max-width: ${horizontalScale(724)};
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;
  font-style: normal;
  font-weight: normal;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    max-width: ${mobileScale(288)};
  `)}
`

const Comment = styled.section`
  background: #FFFFFF;
  padding: ${horizontalScale(240)} ${horizontalScale(460)} ${horizontalScale(230)} ${horizontalScale(525)};

  p {
    font-family: 'Cormorant';
    font-style: italic;
    font-weight: 500;
    font-size: ${horizontalScale(40)};
    line-height: ${horizontalScale(48)};
    text-align: center;
    color: #393939;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    padding:
      ${mobileScale(70)}
      ${mobileScale(25)};

    p {
      font-size: ${mobileScale(16)};
      line-height: ${mobileScale(17)};
    }
  `)}
`
import {
  Main,
  About,
  Collections,
  Decoration,
  Filling,
  Order,
  Team,
  Advantages,
  Press,
  FAQ,
  Form,
  Contacts,
  Footer
} from './components'
import './App.css'
import Modal from 'react-modal'
import TagManager from 'react-gtm-module'
import { GlobalStyle } from 'components/collections'


Modal.setAppElement('#root');

TagManager.initialize({ gtmId: 'GTM-PT37MQQ' })


const App = () => (
  <div className="App" id='root'>
    {/*         MAIN                 */}
    <Main />
    {/*         Об Ателье            */}
    <About />
    {/*         Коллекции            */}
    <Collections />
    {/*         Начинки и декорации  */}
    <Decoration />
    {/*         Начинки              */}
    <Filling />
    {/*         Процесс Заказа       */}
    <Order />
    {/*         Наша Команда         */}
    <Team />
    {/*         Преимущества         */}
    <Advantages />
    {/*         Пресса о нас         */}
    <Press />
    {/*         FAQ                  */}
    <FAQ />
    {/*         Форма                */}
    <Form />
    {/*         Контакты             */}
    <Contacts />
    {/*         Футер                */}
    <Footer />
    {/*         Глобальные стили     */}
    <GlobalStyle />
  </div>
)

export default App;

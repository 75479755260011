import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask'
import Modal from 'react-modal'
import { horizontalScale, mobileMediaQuery, useIsMobile } from 'utils/helpers'
import { ReactComponent as DiamondSVG } from 'assets/svg/diamond.svg'
import { ReactComponent as CancelSVG } from 'assets/svg/cancel.svg'
import { ReactComponent as LogoSVG } from 'assets/svg/logo.svg'
import CloseSVG from 'assets/svg/close-red.svg'

const FORM_INITIAL_VALUES = {
  fullName: '',
  phone: '',
  email: '',
  message: '',
  fileUrls: [],
}

const VALIDATION_SCHEMA = yup.object().shape({
  fullName: yup.string()
    .min(4, 'Минимум 4 символа')
    .max(50, 'Максимум 50 символов')
    .required('Укажите имя!'),
  phone: yup.string()
    .matches(new RegExp('^[^_]+$'), 'Телефон имеет неверный формат!')
    .required('Укажите номер телефонa!'),
  email: yup.string()
    .email('Почтовый адрес имеет неверный формат!')
    .required('Укажите email!'),
  message: yup.string(),
})

interface Props {
  isOpen: boolean,
  onRequestClose?(): void
}

const FormModal = (props: Props) => {
  const isMobile = useIsMobile()

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);

  useEffect(() => {
    setIsOpen(props.isOpen)
  }, [props])

  const closeModal = () => {
    setIsSuccess(false)
    setIsOpen(false)
    props?.onRequestClose && props.onRequestClose()
  }

  const formik = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      try {
        const response = await fetch('https://platform.axept.com/api/channels/w2YoJLXSqxgYBWIMp5tX', {
          method: 'post',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              'site': window.location.hostname,
              'full-name': values.fullName,
              'phone': values.phone,
              'email': values.email,
              'message': values.message + '\n\nПрикрепленные файлы:\n' + values.fileUrls.join('\n'),
            },
          }),
        })
        if (response.status === 200) {
          setIsSuccess(true)
          formik.setValues({ ...FORM_INITIAL_VALUES })
          formik.setFieldValue('fileUrls', [], false)
          setFileNames([]) 
        }
      } catch(err) {

      }    
    },
  });

  const handleFileButtonClick = (event: any) => {
    event.currentTarget.querySelector('input').click()
  }

  const handleInputFileChange = (event: any) => {
    const formData = new FormData()
    const file: string = event.target.files[0]
    if (typeof file === 'undefined' || file === null || file === '') {
      console.log('File not defined or not selected')
      return
    }
    formData.append('file', file)

    const uploadFileUrl = `https://platform.axept.com/api/files/storage`

    fetch(uploadFileUrl, {
      method: 'post',
      body: formData
    }).then(response => {
      return response.json()
    }).then(json => {
      fileNames.push(json.data.name)
      setFileNames(fileNames)
      const formikFileUrls:any = formik.values.fileUrls
      formikFileUrls.push(json.data.url)
      formik.setFieldValue('fileUrls', formikFileUrls, false)
    }).catch(err => {
      console.log(err)
    })
  }

  const handlerRemoveFile = (event:any, index: number) => {
    event.preventDefault()
    setFileNames(fileNames.filter((link, i) => i !== index))
    formik.setFieldValue('fileUrls', formik.values.fileUrls.filter((link, i) => i !== index), false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className='form-modal'
        overlayClassName='form-modal-overlay'
      >
        <ModalContainer>
          <ModalMobileHeader>
            <LogoIcon />
            <CancelIcon onClick={closeModal} />
          </ModalMobileHeader>
          <CancelIconWrapper>
            <CancelIcon onClick={closeModal} />
          </CancelIconWrapper>
          <ModalWrapper>
            <TextBlock>
              <Title>оставить заявку</Title>
              <Description>
                Отправьте запрос для связи с личным менеджером
              </Description>
            </TextBlock>
            <FormWrapper>
              <Form onSubmit={formik.handleSubmit}>
                <InputContainerWrapper>
                  <InputContainer>
                    <NameInput
                      placeholder='Имя'
                      name='fullName'
                      type='text'
                      onChange={formik.handleChange('fullName')}
                      value={formik.values.fullName}
                    />
                    <ErrorMessage>{formik.errors.fullName}</ErrorMessage>
                  </InputContainer>
                  <InputContainer>
                    <PhoneInput
                      placeholder='Телефон'
                      name='phone'
                      type='text'
                      onChange={formik.handleChange('phone')}
                      value={formik.values.phone}
                      maskPlaceholder='_'
                      mask={formik.values.phone.length ? '+7(999)999-99-99' : ''}
                    />
                    <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                  </InputContainer>
                </InputContainerWrapper>
                <InputContainer>
                  <EmailInput
                    placeholder='E-mail'
                    name='email'
                    type='text'
                    onChange={formik.handleChange('email')}
                    value={formik.values.email}
                  />
                  <ErrorMessage>{formik.errors.email}</ErrorMessage>
                </InputContainer>
                <CommentInput
                  placeholder={
                    isMobile
                    ? 'Событие, дата, количество гостей, комментарии'
                    : 'Событие, дата cобытия, количество гостей, комментарии'
                  }
                  name='message'
                  type='textarea'
                  onChange={formik.handleChange('message')}
                  value={formik.values.message}
                />
                <FileUpload>
                  <FileButton className='input-file' onClick={handleFileButtonClick}>
                    <DiamondIcon />
                    <input
                      type='file'
                      name='file'
                      onChange={handleInputFileChange}
                      accept='.pdf,.doc,.docx,.xls,.xlsx,.odt,.ods,.txt,.jpeg,.jpg,.png'
                    />
                    <span>{fileNames.length ? 'Добавить файл' : 'Загрузить файл'}</span>
                    <DiamondIcon />
                  </FileButton>
                  <FileList>
                  {fileNames.map((link, index) =>
                    <FileListItem key={`file-item-${index}`}>
                      <FileListItemName>{link}</FileListItemName>
                      <FileListItemRemoveFile onClick={(event) => handlerRemoveFile(event, index)} />
                    </FileListItem>
                  )}
                  </FileList>
                </FileUpload>
                <SubmitButton type='submit'>
                  <DiamondIcon />
                  <span>
                    отправить запрос
                  </span>
                  <DiamondIcon />
                </SubmitButton>
                <AgreementLink>
                  Нажимая на кнопку, я соглашаюсь на {' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/privacy-policy.pdf`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                  обработку персональных данных
                  </a>
                </AgreementLink>
              </Form>
            </FormWrapper>
          </ModalWrapper>
        </ModalContainer>
      </Modal>
      
      <Modal
        isOpen={isSuccess}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        className='form-success-modal'
        overlayClassName='form-success-modal-overlay'
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>заявка принята</ModalTitle>
            <CancelIcon onClick={closeModal} />
          </ModalHeader>
          <ModalDescription>
            Мы свяжемся с вами в ближайшее рабочее время. Наш график работы пн-пт  с 10:00 до 20:00.
          </ModalDescription>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FormModal

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const ModalWrapper = styled.div`
  position: relative;
  padding: 0 ${horizontalScale(120)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0;
    padding-top: ${mobileScale(78)};
  `)}
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0 ${mobileScale(16)};
  `)}
`

const Title = styled.h2`
  font-size: ${horizontalScale(40)};
  line-height: ${horizontalScale(48)};
  font-weight: 500;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #98734F;
  margin: 0;
  text-align: center;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    font-weight: normal;
    text-align: left;
  `)}
`

const Description = styled.span`
  width: 100%;
  font-weight: 500;
  font-size: ${horizontalScale(20)};
  line-height: ${horizontalScale(24)};
  margin-top: ${horizontalScale(25)};
  letter-spacing: 0.02em;
  color: #9B9999;
  text-align: center;
  font-family: 'TT Commons';

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    font-weight: 500;
    margin-top: ${mobileScale(16)};
    width: 100%;
    text-align: left;
  `)}
`

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  input {
    font-family: 'TT Commons';
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    padding: 0 ${mobileScale(16)};
  `)}
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: ${horizontalScale(477)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const InputContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${horizontalScale(39)};

  ${mobileMediaQuery((css, mobileScale) => css`
    display: block;
  `)}
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${horizontalScale(74)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(68)};
  `)}
`

const ErrorMessage = styled.span`
  display: block;
  width: ${horizontalScale(227)};
  height: ${horizontalScale(35)};
  color: red;
  font-size: ${horizontalScale(15)};
  line-height: ${horizontalScale(18)};
  margin-top: ${horizontalScale(8)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(32)};
    font-size: ${mobileScale(10)};
    line-height: ${mobileScale(15)};
  `)}
`

const inputsCommonStyle = css`
  height: ${horizontalScale(52)};
  border: none;
  border-bottom: ${horizontalScale(1)} solid #505050;
  font-size: ${horizontalScale(21)};
  line-height: ${horizontalScale(28)};
  padding: ${horizontalScale(17)} 0;

  &::placeholder {
    color: #C2C7C7;
    font-size: ${horizontalScale(19)};
    line-height: ${horizontalScale(25)};
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    height: ${mobileScale(36)};
    font-size: ${mobileScale(15)};
    line-height: ${mobileScale(28)};

    &::placeholder {
      content: 'Событие, дата, количество гостей, комментарии';
      font-size: ${mobileScale(14)};
      line-height: ${mobileScale(17)};
    }
  `)}
`

const NameInput = styled.input`
  ${inputsCommonStyle}

  width: ${horizontalScale(227)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const PhoneInput = styled(InputMask)`
  ${inputsCommonStyle}
  
  width: ${horizontalScale(227)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const EmailInput = styled.input`
  ${inputsCommonStyle}

  width: ${horizontalScale(477)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const CommentInput = styled.input`
  ${inputsCommonStyle}

  width: 100%;
  margin-bottom: ${horizontalScale(67)};

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    margin-bottom: ${mobileScale(32)};
  `)}
`

const FileUpload = styled.div`
  margin-bottom: ${horizontalScale(45)};

  ${mobileMediaQuery((css) => css`
    width: 100%;
  `)}
`

const FileButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5D5B60;
  padding: 0 ${horizontalScale(20)};
  width: ${horizontalScale(352)};
  height: ${horizontalScale(60)};
  background: transparent;
  text-decoration: none;
  color: #5D5B60;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.3s ease-out;


  span {
    width: 100%;
    font-size: ${horizontalScale(17)};
    line-height: ${horizontalScale(20)};
    margin-top: ${horizontalScale(5)};
    font-family: 'TT Commons';
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: #5D5B60;
    transition: 0.3s ease-out;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(142, 161, 177, 0.3);
    border: 1px solid transparent;
  }
  
  input {
    display: none;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: 100%;
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};
    
    span {
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(12)};
      margin-top: ${mobileScale(3)};
      letter-spacing: 0.18em;
    }
  `)}
`

const FileList = styled.div``

const FileListItem = styled.div`
  display: flex;
`

const FileListItemName = styled.div`
  color: #5D5B60;
`

const FileListItemRemoveFile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  cursor: pointer;

  &:after {
    content: url(${CloseSVG});
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    left: 0;
  }
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #5D5B60;
  padding: 0 ${horizontalScale(20)};
  width: ${horizontalScale(352)};
  height: ${horizontalScale(60)};
  margin-bottom: ${horizontalScale(30)};
  background: transparent;
  text-decoration: none;
  color: #5D5B60;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: 0.3s ease-out;

  
  span {
    font-size: ${horizontalScale(17)};
    line-height: ${horizontalScale(20)};
    margin-top: ${horizontalScale(5)};
    font-family: 'TT Commons';
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: #5D5B60;
    transition: 0.3s ease-out;
  }

  svg {
    transition: 0.3s ease-out;
    margin: auto 0;
  }

  &:hover {
    background: rgba(142, 161, 177, 0.3);
    border: 1px solid transparent;
  }
  
  &:active {
    border: 1px solid transparent;
    background: #8EA1B1;

    span {
      color: #FFFFFF;
    }

    svg rect {
      fill: #FFFFFF;
    }
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(288)};
    height: ${mobileScale(38)};
    padding: 0 ${mobileScale(16)};
    margin-bottom: ${mobileScale(16)};
    
    span {
      font-size: ${mobileScale(10)};
      line-height: ${mobileScale(12)};
      margin-top: ${mobileScale(3)};
      letter-spacing: 0.18em;
    }
  `)}
`

const DiamondIcon = styled(DiamondSVG)`
  width: ${horizontalScale(16)};
  height: ${horizontalScale(16)};

  rect {
    fill: #5D5B60;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(8)};
    height: ${mobileScale(8)};
  `)}
`

const AgreementLink = styled.span`
  font-size: ${horizontalScale(16)};
  line-height: ${horizontalScale(20)};
  color: #D1D8D8;

  a {
    color: #CFAF4E;
    text-decoration: underline;
  }

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(14)};
    text-align: left;
  `)}
`

const ModalContent = styled.div`
  width: 100%;

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    text-align: left;
  `)}
`

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${horizontalScale(32)};
  border-bottom: ${horizontalScale(2)} solid rgba(75, 75, 75, 0.2);

  ${mobileMediaQuery((css, mobileScale) => css`
    min-height: ${mobileScale(55)};
    padding: 0 ${mobileScale(16)};
    border-bottom: ${mobileScale(0.5)} solid rgba(75, 75, 75, 0.2);
  `)}
`

const ModalDescription = styled.p`
  width: 100%;
  height: 100%;
  color: #9B9999;
  margin: 0;
  font-size: ${horizontalScale(22)};
  line-height: ${horizontalScale(32)};
  padding: ${horizontalScale(32)};

  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(12)};
    line-height: ${mobileScale(18)};
    padding: ${mobileScale(35)} ${mobileScale(46)} ${mobileScale(25)} ${mobileScale(16)};
  `)}
`

const LogoIcon = styled(LogoSVG)`
  display: none;

  ${mobileMediaQuery((css, mobileScale) => css`
    display: block;
    width: ${mobileScale(37)};
    height: ${mobileScale(37)};
  `)}
`

const ModalTitle = styled.h3`
  font-size: ${horizontalScale(28)};
  line-height: ${horizontalScale(32)};
  max-width: 70%;
  font-weight: 500;
  text-transform: uppercase;
  color: #98734F;
  margin: 0;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-top: ${mobileScale(25)};
    text-align: left;
  `)}
`

const ModalMobileHeader = styled.div`
  display: none;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    display: flex;
    position: fixed;
    top: 0;
    background-color: #FFFFFF;
    width: 100%;
    justify-content: space-between;
    min-height: ${mobileScale(55)};
    align-items: center;
    padding-left: ${mobileScale(14)};
    padding-right: ${mobileScale(19)};
    border-bottom: ${mobileScale(0.5)} solid rgba(75, 75, 75, 0.2);
    z-index: 99;
  `)}
`

const CancelIconWrapper = styled.div`
  position: absolute;
  top: ${horizontalScale(80)};
  left: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-left: ${horizontalScale(120)};
  padding-right: ${horizontalScale(122)};

  ${mobileMediaQuery((css, mobileScale) => css`
    min-height: ${mobileScale(55)};
    padding: 0 ${mobileScale(16)};
  `)}
`

const CancelIcon = styled(CancelSVG)`
  width: ${horizontalScale(24)};
  height: ${horizontalScale(24)};
  cursor: pointer;
  
  ${mobileMediaQuery((css, mobileScale) => css`
    width: ${mobileScale(13)};
    height: ${mobileScale(13)};
  `)}
`

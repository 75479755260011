import styled from 'styled-components'
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils'
import { horizontalScale, mobileMediaQuery } from 'utils/helpers'
import decorationImgPng from 'assets/images/decoration.png'
import decorationImgWebp from 'assets/images/decoration.webp'
import diamondSVG from 'assets/svg/diamond.svg'
import DECORATION_LIST from './mockups'

const decorationImg = isWebpSupported()
  ? decorationImgWebp
  : decorationImgPng

const Decoration = () => (
  <Section id='decoration'>
    <TextBlock>
      <Title>Декор</Title>
      {DECORATION_LIST.map(({ id, text }) => (
        <Description key={`decoration-${id}`}>{text}</Description>
      ))}
    </TextBlock>
  </Section>
)

export default Decoration

const Section = styled.section`
  flex: 1;
  background: url(${decorationImg}) no-repeat right, linear-gradient(0deg, #8EA1B1, #8EA1B1), #FFFFFF;
  background-size: contain;
  width: 100%;
  padding: ${horizontalScale(245)} ${horizontalScale(221)};

  ${mobileMediaQuery((css, mobileScale) => css`
    padding:
      ${mobileScale(64)}
      ${mobileScale(16)};
    background: #FFFFFF;
    position: relative;
    width: ${mobileScale(320)};
    height: auto;
    margin-bottom: ${mobileScale(320)};

    &:after {
      content: '';
      display: block;
      position: absolute;
      background: url(${decorationImg}) no-repeat right, linear-gradient(0deg, #8EA1B1, #8EA1B1), #FFFFFF;
      background-size: ${mobileScale(238)} ${mobileScale(320)};
      width: ${mobileScale(320)};
      height: ${mobileScale(320)};
      left: 0;
      right: 0;
      top: 100%;
    }
  `)}

`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${horizontalScale(724)};

  ${mobileMediaQuery((css) => css`
    max-width: none;
  `)}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: ${horizontalScale(48)};
  line-height: ${horizontalScale(58)};
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: ${horizontalScale(40)};

  ${mobileMediaQuery((css, mobileScale) => css`
    color: #98734F;
    font-size: ${mobileScale(18)};
    line-height: ${mobileScale(22)};
    margin-bottom: ${mobileScale(16)};
  `)}
`

const Description = styled.p`
  font-weight: 500;
  font-size: ${horizontalScale(24)};
  line-height: ${horizontalScale(29)};
  color: #FFFFFF;
  text-align: left;
  margin: 0;
  background-image: url(${diamondSVG});
  background-repeat: no-repeat;
  background-size: ${horizontalScale(16)} ${horizontalScale(16)};
  background-position-y: ${horizontalScale(5)};
  padding-left: ${horizontalScale(32)};
  margin-bottom: ${horizontalScale(24)};

  ${mobileMediaQuery((css, mobileScale) => css`
    color: #9B9999;
    font-weight: normal;
    position: relative;
    font-size: ${mobileScale(14)};
    line-height: ${mobileScale(17)};
    margin-bottom: ${mobileScale(16)};
    padding-left: 0;
  `)}
`
